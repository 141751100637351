
@tailwind base;
@tailwind components;
@tailwind utilities;

.loading-bar {
    position: relative;
    height: 5px;
    width: 100%;
    background-color: #ddd;
  }
  
  .progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: #007bff;
    transition: width 0.3s ease;
  }
  
  .loading-bar.active .progress {
    width: 100%;
  }
